<template>
  <div>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn @click="signatureDialog = !signatureDialog" v-bind="attrs" v-on="on" class="mt-16 mr-10" color="primary"
          fab dark :fixed="responsiveSize < 500" :absolute="responsiveSize >= 500" :bottom="responsiveSize < 500"
          :top="responsiveSize >= 500" right>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar nova assinatura</span>
    </v-tooltip>
    <v-row align="start" class="px-14 mb-8">
      <v-card-title class="pt-10 pb-0 pl-0 blue-grey--text text--darken-2 ml-3">Assinaturas digitais</v-card-title>
      <v-card-text class="Caption grey--text text--darken-1 pb-0 px-0 ml-3">
        <span style="max-width: 870px; display: block">Adicione suas assinaturas digitais clicando no botão no canto
          direito, "Adicionar nova assinatura". <br />
          Crie os modelos de templates de contratos ideais para a assinatura que
          você selecionar.</span>
      </v-card-text>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" class="px-14 pt-0">
        <v-data-table :headers="headers" :items="tableDigitalSignatures" :items-per-page="5" class="elevation-0">
          <template v-slot:item.templates="{ item }">
            <!-- {{item.templates}} -->
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <a v-show="item.templates != ''" v-bind="attrs" v-on="on"><v-icon small color="#5779D2"
                    class="">auto_awesome_mosaic</v-icon>
                  Templates</a>
                <div v-show="item.templates == ''">Não há templates</div>
              </template>
              <span class="" v-html="item.templates"></span>
            </v-tooltip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="btMicro px-1 mr-3" @click="
                  editSignatures(item, tableDigitalSignatures.indexOf(item))
                  " x-small color="primary" dark><v-icon center x-small> edit </v-icon></v-btn>
              </template>
              <span class="miniTtip">Editar Assinatura</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="btMicro px-1" @click="
                  confirmDelSignatures(
                    item,
                    tableDigitalSignatures.indexOf(item)
                  )
                  " x-small color="red" dark><v-icon center x-small> mdi-delete-forever </v-icon></v-btn>
              </template>
              <span class="miniTtip">Excluir Assinatura</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="signatureDialog" max-width="920" persistent>
      <v-card>
        <v-card-title class="text-h5">
          Gerenciamento de contratos
        </v-card-title>

        <v-card-text>
          <v-form ref="signatureForm" v-model="validSignatureFrm">
            <v-row class="pt-1">
              <span class="pt-2 pl-5" style="max-width: 870px; display: block">Selecione abaixo uma asinatura digital,
                preencha o campo "Api
                key", resgate a Api Key na sua conta e em seguida clique no
                botão "SALVAR". Você só poderá definir os templates da
                assinatura depois de salvá-la.</span>

              <v-col cols="12" sm="6" md="6" class="px-6 pt-4">
                <v-select @change="setFieldsForm" :rules="$store.state.formRules.required" :disabled="hasSignatureSel"
                  v-model="signature" item-text="name" item-value="id" :items="signatures"
                  label="Selecionar assinatura"></v-select></v-col>
              <v-col cols="12" sm="6" md="6" class="px-6 pt-4">
                <!-- :disabled="hasSignatureSel" -->
                <v-text-field :rules="hasSignatureSel ? [] : $store.state.formRules.required
                  " label="Api Key" v-model="apiKey"></v-text-field></v-col>
              <v-col cols="12" sm="6" md="6" class="px-6 pt-0" v-if="showCryptKey">
                <!-- :disabled="hasSignatureSel" -->
                <v-text-field class="pt-0" :rules="hasSignatureSel ? [] : $store.state.formRules.required
                  " label="Crypt Key" v-model="cryptKey"></v-text-field></v-col>
            </v-row>
          </v-form>
          <v-row class="mt-5">
            <v-col cols="12" sm="10" md="10" class="px-4 pt-0">
              <!-- :disabled="onOffTemplateButton()" -->
              <v-btn :disabled="!hasSignatureSel" @click="templateDialog = !templateDialog" class="ml-2" elevation="1"
                small color="success"><v-icon small left> add_box </v-icon>
                Adicionar template
              </v-btn>
              <v-tooltip right v-if="!hasSignatureSel">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="light-blue darken-4" class="ml-3"> help
                  </v-icon>
                </template>
                <span class="" v-html="'Para criar um template é necessário<br/>primeiro salvar a assinatura.'"></span>
              </v-tooltip>
            </v-col>

            <v-col cols="12" sm="12" md="12" class="px-4 pt-0">
              <v-data-table :headers="templateHeaders" :items="templates" :items-per-page="5" class="elevation-0">
                <template v-slot:item.actions="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" class="btMicro px-1 mr-3"
                        @click="editTemplate(item, templates.indexOf(item))" x-small color="primary" dark><v-icon center
                          x-small> edit </v-icon></v-btn>
                    </template>
                    <span class="miniTtip">Editar Template</span>
                  </v-tooltip>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" class="btMicro px-1" @click="
                        confirmDelTemplate(item, templates.indexOf(item))
                        " x-small color="red" dark><v-icon center x-small>
                          mdi-delete-forever
                        </v-icon></v-btn>
                    </template>
                    <span class="miniTtip">Excluir Template</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="signatureDialog = !signatureDialog">
            Fechar
          </v-btn>
          <v-btn color="green" v-if="!hasSignatureSel" text @click="saveSignature">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="templateDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5"> Template </v-card-title>
        <v-card-text>
          <v-form ref="templateForm" v-model="validTemplateFrm">
            <v-checkbox v-if="addFirstTemplate" v-model="initial_template" readonly label="Template inicial"></v-checkbox>
            <!-- :class="[addFirstTemplate ? 'pt-0' : 'pt-4']" -->
            <v-text-field :rules="$store.state.formRules.required" label="Nome do template"
              v-model="templateName"></v-text-field>
            <v-text-field v-if="!showCryptKey" :rules="$store.state.formRules.required" label="Template Key"
              v-model="templateKey"></v-text-field>

            <v-select v-if="showCryptKey" :rules="$store.state.formRules.required" v-model="templateKey" item-text="name"
              item-value="id" :items="templatesD4S" label="Selecionar template"></v-select>

            <v-select v-if="!addFirstTemplate" :rules="$store.state.formRules.required" v-model="templateService"
              item-text="name" item-value="id" :items="companyServices" label="Selecionar serviço"></v-select>

            <v-select v-if="safes.length ? true : false" @change="" :rules="$store.state.formRules.required"
              item-text="name-safe" item-value="uuid_safe" clearable tex v-model="uuid_safe" :items="safes"
              label="Selecione um cofre"></v-select>


          </v-form>
        </v-card-text>
        <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="templateDialog = !templateDialog">
            Fechar
          </v-btn>
          <v-btn color="green" text @click="verifyToSaveTemplate">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const resetContractManager = {};
export default {
  watch: {
    tab(v) {
      //console.log("aaaaa");
      if (this.digitalSignatures) {
        this.tableDigitalSignatures = [...this.digitalSignatures];
      } else {
        this.tableDigitalSignatures = [];
      }
    },
    signatureDialog(v) {
      if (!v) {
        this.templates = [];
        this.signature = "";
        this.apiKey = "";
        this.hasSignatureSel = false;
        this.indexSignatureSel = "";
        this.$refs.signatureForm.resetValidation();
        this.cryptKey = "";
        this.showCryptKey = false;
      } else {
        //console.log(this.tableDigitalSignatures);
      }
      //console.log(this.digitalSignatures);
    },
    templateDialog(v) {
      if (!v) {
        this.templateId = "";
        this.templateName = "";
        this.templateKey = "";
        this.$refs.templateForm.resetValidation();
        this.templateService = "";
        this.uuid_safe = "";
        this.companyServices = [];
        this.templatesD4S = [];
      } else {
        //console.log("temp edit", this.editDataForm);
        //this.templateService = "";
        if (!this.templates.length) {
          this.addFirstTemplate = true;
        } else {
          this.addFirstTemplate = false;
        }
        if (this.showCryptKey) {
          this.getTemplatessKey();
        }
        this.getSafes()
        this.companyServices = [...this.editDataForm.servicesToTemplate];
      }
    },
  },
  mounted() {
    this.getSignatures();
  },
  methods: {
    getSafes() {
      let contractManagerId;
      if (typeof this.signature === 'object' && this.signature !== null) {
        contractManagerId = this.signature.id
      } else {
        contractManagerId = this.signature
      }
      let obj = {
        url: "/api/partner/contract/safes",
        query: {
          company_id: this.editDataForm.company.id,
          contracts_management_id: contractManagerId
        },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) {
          return;
        }
        this.safes = [...response.data];
      });
    },
    getTemplatessKey() {
      let contractManagerId;
      if (typeof this.signature === 'object' && this.signature !== null) {
        contractManagerId = this.signature.id
      } else {
        contractManagerId = this.signature
      }
      let obj = {
        url: "/api/partner/contract/templates",
        query: {
          company_id: this.editDataForm.company.id,
          contracts_management_id: contractManagerId,
        },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        //  console.log(response);
        if (!response) {
          return;
        }
        this.templatesD4S = response.data;
      });
    },
    onOffTemplateButton() {

      if (this.showCryptKey) {
        // !this.signature || !this.apiKey || !this.cryptKey
        if (!this.signature) {
          return true;
        } else {
          return false;
        }
      } else {
        // !this.signature || !this.apiKey
        if (!this.signature) {
          return true;
        } else {
          return false;
        }
      }
    },
    setFieldsForm(id) {
      //console.log("ds4");
      let signatureIndex = this.signatures.findIndex(
        (item) => item.id == (id ? id : this.signature)
      );
      if (this.signatures[signatureIndex].prefix == "D4S") {
        this.showCryptKey = true;
      } else {
        this.showCryptKey = false;
      }
    },
    editTemplate(data, ind) {
      //console.log("-------");
      this.templateId = data.id;
      this.templateName = data.name;
      this.templateKey = data.template_id;
      this.uuid_safe = data.uuid_safe
      this.templateDialog = true;
      setTimeout(() => {
        if (!data.service_id) {
          this.addFirstTemplate = true;
        } else {
          this.addFirstTemplate = false;
          this.templateService = data.service_id;
        }
      }, 10);
    },
    editSignatures(data, ind) {
      //console.log("edit", data);
      this.indexSignatureSel = ind;
      this.hasSignatureSel = true;
      this.contract_management_credential_id = data.id;
      this.apiKey = data.credentials;
      //d4s
      this.cryptKey = data.cryptKey ? data.cryptKey : "";
      //d4s
      this.signature = data.contracts_management;
      this.templates = [...data.contract_template];
      this.signatureDialog = true;
      setTimeout(() => {
        this.setFieldsForm(data.contracts_management.id);
      }, 10);
    },
    confirmDelTemplate(data, i) {
      let obj = {
        message: `Você deseja excluir o template <b>${data.name}</b>?`,
        title: "Excluir Template",
        callback: (_) => {
          this.delTemplate(data, i);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    delTemplate(data, i) {
      let obj = {
        url: "/api/contract-template/delete",
        query: { id: data.id },
        method: "post",
      };
      let _this = this;
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) {
          return;
        }
        //console.log("pq...");
        this.forceCleartemplates(data.name);
        //_this.tableDigitalSignatures[_this.indexSignatureSel].templates.replace(`${data.name}<br/>`,'');
        //console.log(data.name," - ", this.tableDigitalSignatures[this.indexSignatureSel].templates);
        this.tableDigitalSignatures[
          this.indexSignatureSel
        ].contract_template.splice(i, 1);
        this.templates.splice(i, 1);
        let opts = {
          message: `Template <b>${data.name}</b> removido com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
    forceCleartemplates(name) {
      // this.tableDigitalSignatures[this.indexSignatureSel].templates = "";
      // return;
      // console.log(
      //   this.tableDigitalSignatures[this.indexSignatureSel].templates
      // );
      //console.log('clear',this.tableDigitalSignatures[this.indexSignatureSel])
      this.tableDigitalSignatures[this.indexSignatureSel].templates =
        this.tableDigitalSignatures[this.indexSignatureSel].templates.replace(
          `${name}<br/>`,
          ""
        );
      let tabs = document.querySelectorAll("#companyFormTab .v-tab");
      tabs[0].click();
      setTimeout(() => {
        tabs[2].click();
      }, 10);
      // console.log(
      //   "2",
      //   this.tableDigitalSignatures[this.indexSignatureSel].templates
      // );
    },
    confirmDelSignatures(data, i) {
      let obj = {
        message: `Você deseja excluir a assinatura <b>${data.name}</b>?`,
        title: "Excluir Assinatura digital",
        callback: (_) => {
          this.delSignature(data, i);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    delSignature(data, i) {
      let obj = {
        url: "/api/contract-creadentials/delete",
        query: { id: data.id },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        //  console.log(response);
        if (!response) {
          return;
        }
        this.tableDigitalSignatures.splice(i, 1);
        this.updateDigitalSignatures(this.tableDigitalSignatures);
        let opts = {
          message: `Assinatura digital <b>${data.name}</b> removida com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
    verifyToSaveTemplate() {
      if (!this.$refs.templateForm.validate()) {
        return;
      }
      if (!this.hasSignatureSel) {
        this.saveSignature().then((_) => {
          this.saveTemplate();
        });
      } else {
        this.saveTemplate();
      }
    },
    async saveSignature() {
      if (!this.$refs.signatureForm.validate()) {
        return;
      }
      var obj = {
        url: "/api/contract-creadentials/create",
        query: {
          contracts_management_id: this.signature,
          company_id: this.editDataForm.company.id,
          credentials: this.apiKey,
          cryptKey: this.cryptKey,
        },
        method: "post",
      };
      let _this = this;
      //console.log("ind");
      await this.$store.dispatch("request/doRequest", obj).then((response) => {
        //this.signatures = [...response.data];
        this.contract_management_credential_id = response.data.id;
        response.data.name = response.data.contracts_management.name;
        response.data.templates = "";
        this.tableDigitalSignatures.push(response.data);
        this.updateDigitalSignatures(this.tableDigitalSignatures);
        this.hasSignatureSel = true;
      });
    },
    saveTemplate() {
      // console.log("valtemp");
      if (!this.$refs.templateForm.validate()) {
        return;
      }
      var obj = {
        url: this.templateId
          ? "/api/contract-template/update"
          : "/api/contract-template/create",
        query: {
          service_id: this.templateService,
          uuid_safe: this.uuid_safe,
          id: this.templateId,
          contract_management_credential_id:
            this.contract_management_credential_id,
          name: this.templateName,
          template_id: this.templateKey,
          draft_contract: "",
        },
        method: "post",
      };
      let _this = this;
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        //console.log("ind");
        if (!response) { return }
        this.updateDigitalSignatures(_this.tableDigitalSignatures);
        if (!this.templateId) {
          this.templates.push(response.data);
          _this.tableDigitalSignatures[
            _this.indexSignatureSel
              ? _this.indexSignatureSel
              : _this.tableDigitalSignatures.length - 1
          ].contract_template.push(response.data);
          //this.templates.splice(i, 1);
          _this.tableDigitalSignatures[
            _this.indexSignatureSel
              ? _this.indexSignatureSel
              : _this.tableDigitalSignatures.length - 1
          ].templates += response.data.name + "<br/>";
        } else {
          let id = response.data.original.id;
          let index = this.templates.findIndex((temp) => temp.id === id);
          this.templates.splice(index, 1);
          this.templates.splice(index, 0, response.data.original);
          //console.log("ind");

          let i = this.tableDigitalSignatures[
            this.indexSignatureSel
          ].contract_template.findIndex((temp) => temp.id === id);

          let changeName =
            this.tableDigitalSignatures[this.indexSignatureSel]
              .contract_template[i].name;

          this.tableDigitalSignatures[this.indexSignatureSel].templates =
            this.tableDigitalSignatures[
              this.indexSignatureSel
            ].templates.replace(
              `${changeName}<br/>`,
              response.data.original.name + "<br/>"
            );

          this.tableDigitalSignatures[
            this.indexSignatureSel
          ].contract_template.splice(i, 1);
          this.tableDigitalSignatures[
            this.indexSignatureSel
          ].contract_template.splice(i, 0, response.data.original);
        }
        let tabs = document.querySelectorAll("#companyFormTab .v-tab");
        tabs[0].click();
        setTimeout(() => {
          tabs[2].click();
        }, 10);
        this.templateDialog = false;
        let opts = {
          message: `Template salvo com successo`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
    getSignatures() {
      var obj = {
        url: "/api/contract-manager/get-all",
        query: null,
        method: "get",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        this.signatures = [...response.data];
      });
    },
  },
  computed: {
    responsiveSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  props: {
    updateDigitalSignatures: Function,
    editDataForm: Object,
    digitalSignatures: Array,
    tab: Number,
  },
  data() {
    return {
      safes: [],
      safe: "",
      uuid_safe: "",
      templatesD4S: [],
      initial_template: true,
      addFirstTemplate: false,
      showCryptKey: false,
      cryptKey: "",
      templateService: "",
      companyServices: [],
      validTemplateFrm: true,
      validSignatureFrm: true,
      indexSignatureSel: "",
      tableDigitalSignatures: [],
      templates: [],
      contract_management_credential_id: "",
      hasSignatureSel: false,
      signatures: [],
      templateId: "",
      templateName: "",
      templateKey: "",
      signature: "",
      apiKey: "",
      templateDialog: false,
      signatureDialog: false,
      templateHeaders: [
        {
          text: "Template",
          align: "start",
          value: "name",
        },
        // { sortable: false, text: "Key", value: "template_id" },
        { sortable: false, text: "Ações", value: "actions" },
      ],
      headers: [
        {
          text: "Assinatura digital",
          align: "start",
          value: "name",
        },
        // { sortable: false, text: "Credencial", value: "credentials" },
        { sortable: false, text: "Templates", value: "templates" },
        { sortable: false, text: "Ações", value: "actions" },
      ],
      valid: true,
    };
  },
};
</script>

<style></style>