<template>
  <v-form class="mt-12" ref="credentialsForm" v-model="valid">
    <v-row
      ><h3
        class="
          mb-10
          pl-10
          text-h5
          blue-grey--text
          text--darken-2
          font-weight-light
        "
      >
        Selecione as formas de pagamentos aceitas pela empresa
      </h3></v-row
    >
    <v-row>
      <v-col cols="12" sm="6" md="6" class="px-15 pt-0">
        <v-subheader class="pl-0">
          <h3>Cielo</h3>
          <!-- <v-img
            lazy-src="https://monitordomercado.com.br/api/v1/image/large/1558734746065_37e33410-7e6e-11e9-9b33-7dbd60b640c7.jpg"
            max-width="67"
            src="https://monitordomercado.com.br/api/v1/image/large/1558734746065_37e33410-7e6e-11e9-9b33-7dbd60b640c7.jpg"
          ></v-img> -->
        </v-subheader>
        <v-switch v-model="cieloMode" label="Ativar Cielo"></v-switch>
        <v-form ref="cieloForm" v-model="valid">
          <v-text-field
            :disabled="!cieloMode"
            v-model="formData[0].MERCHANT_KEY"
            label="Merchant Key"
            :rules="cieloMode ? $store.state.formRules.required : []"
          ></v-text-field>
          <v-text-field
            :disabled="!cieloMode"
            v-model="formData[0].MERCHANT_ID"
            label="Merchant ID"
            :rules="cieloMode ? $store.state.formRules.required : []"
          ></v-text-field>
        </v-form>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="12" sm="6" md="6" class="px-15 pt-0">
        <v-subheader class="pl-0">
          <h3>Banco do Brasil</h3>
          <!-- <v-spacer></v-spacer> -->
          <!-- <v-img contain max-height="85" src="../../assets/bbtransparent.png"></v-img> -->
        </v-subheader>
        <v-switch v-model="bBMode" label="Ativar Banco do Brasil"></v-switch>
        <v-form ref="bBForm" v-model="valid">
          <v-text-field
            :disabled="!bBMode"
            v-model="formData[1].client_id"
            label="Client ID"
            :rules="bBMode ? $store.state.formRules.required : []"
          ></v-text-field>
          <v-text-field
            :disabled="!bBMode"
            v-model="formData[1].client_secret"
            label="Client Secret"
            :rules="bBMode ? $store.state.formRules.required : []"
          ></v-text-field>

          <v-text-field
            :disabled="!bBMode"
            v-model="formData[1].app_key"
            label="App key"
            :rules="bBMode ? $store.state.formRules.required : []"
          ></v-text-field>
          <v-text-field
            :disabled="!bBMode"
            v-model="formData[1].auth_code"
            label="Auth code"
            :rules="bBMode ? $store.state.formRules.required : []"
          ></v-text-field>
        </v-form>
      </v-col>
      <v-divider vertical></v-divider>
      <!-- <v-col cols="12" sm="6" md="6" class="px-15 pt-0">
        <v-subheader class="pl-0"><h3></h3></v-subheader
      ></v-col> -->
    </v-row>
  </v-form>
</template>

<script>
const resetCredentialsForm = [
  { MERCHANT_KEY: "", MERCHANT_ID: "" },
  { client_id: "", client_secret: "", app_key: "", auth_code: "" },
];
export default {
  methods: {
    validForm() {
      // console.log('credenci')
      let validCieloFor = this.$refs.cieloForm.validate();
      let validBBForm = this.$refs.bBForm.validate();
      if (!validCieloFor || !validBBForm) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    cieloMode(v) {
      if (!v) {
        this.$refs.cieloForm.reset();
      }
      this.$refs.cieloForm.resetValidation();
    },
    bBMode(v) {
      if (!v) {
        this.$refs.bBForm.reset();
      }
      this.$refs.bBForm.resetValidation();
    },
    companyDialog(v) {
      //console.log('aaa')
      if (!v) {
        this.$refs.cieloForm.reset();
        this.$refs.bBForm.reset();
        this.$refs.credentialsForm.reset();
        this.formData = [...resetCredentialsForm];
        this.cieloMode = false;
        this.bBMode = false;
      } else {
        if (this.editDataCredentials) {
          let paymentsSel = null;
          if (this.editDataCredentials.length == 1) {
            paymentsSel = this.editDataCredentials[0].name;
          } else if (this.editDataCredentials.length == 2) {
            paymentsSel = "ALL";
          }
          if (paymentsSel == "Cielo" || paymentsSel == "ALL") {
            this.cieloMode = true;
            this.formData[0] = {
              ...JSON.parse(this.editDataCredentials[0].credentials_json),
            };
          }
          if (paymentsSel == "B.Brasil" || paymentsSel == "ALL") {
            this.bBMode = true;
            this.formData[1] = {
              ...JSON.parse(
                this.editDataCredentials[paymentsSel == "ALL" ? 1 : 0]
                  .credentials_json
              ),
            };
          }
        }
      }
    },
  },
  mounted() {
    if (this.editDataCredentials) {
      //console.log('eeee')
      let paymentsSel = null;
      if (this.editDataCredentials.length == 1) {
        paymentsSel = this.editDataCredentials[0].name;
      } else if (this.editDataCredentials.length == 2) {
        paymentsSel = "ALL";
      }
      if (paymentsSel == "Cielo" || paymentsSel == "ALL") {
        this.cieloMode = true;
        this.formData[0] = {
          ...JSON.parse(this.editDataCredentials[0].credentials_json),
        };
      }
      if (paymentsSel == "B.Brasil" || paymentsSel == "ALL") {
        this.bBMode = true;
        this.formData[1] = {
          ...JSON.parse(
            this.editDataCredentials[paymentsSel == "ALL" ? 1 : 0]
              .credentials_json
          ),
        };
      }
    }
  },
  data() {
    return {
      formData: [...resetCredentialsForm],
      valid: true,
      cieloMode: false,
      bBMode: false,
    };
  },
  props: { companyDialog: Boolean, editDataCredentials: Array },
};
</script>

<style>
</style>