<template>
  <v-row justify="center">
    <v-dialog persistent v-model="companyDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card outlined shaped tile>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="toogleCompanyDilog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            editDataForm && editDataForm.company.id
            ? "Editar empresa"
            : "Configurar nova empresa"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="saveCompany"> Salvar </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="mx-8 mt-5">
          <v-tabs id="companyFormTab" v-model="tab" style="border-bottom: 1px solid #eee">
            <v-tab> Dados da empresa </v-tab>
            <!-- <v-tab> Credenciais de pagamentos</v-tab> -->
            <!--  -->
            <v-tab :disabled="editDataForm == null">
              Gerenciamento de contratos
            </v-tab>
            <v-tab v-if="idpCredencial">
              credenciais idp
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <CompanyForm :editDataForm="editDataForm" :companyDialog="companyDialog" ref="companyFormDetails"
                :formData="formData" />
            </v-tab-item>
            <!-- <v-tab-item :eager="true">
              <Credentials :editDataCredentials="editDataCredentials" :companyDialog="companyDialog"
                ref="credentialFormDetails" />
            </v-tab-item> -->
            <v-tab-item :disabled="editDataForm == null" :eager="true">
              <ManagerContractsForm :tab="tab" :updateDigitalSignatures="updateDigitalSignatures" :digitalSignatures="
                editDataForm
                  ? editDataForm.contract_management_credentials
                  : []
              " :editDataForm="editDataForm" />
            </v-tab-item>
            <v-tab-item v-if="idpCredencial">
              <IdpCredencial :idpCredencial="idpCredencial" />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import CompanyForm from "./CompanyForm.vue";
import Credentials from "./Credentials.vue";
import ManagerContractsForm from "./ManagerContractsForm.vue";
import IdpCredencial from "../partners/IdpCredencial.vue";
const resetCompanyForm = {
  company: {
    name: "",
    cnpj: "",
    additional_information: "",
  },
  erp_credentials: {
    erp_gateway_id: "",
    email: "",
    password: "",
  },
  contract_manager_credentials: {
    contracts_management_id: "",
    credentials: "",
  },
  payment_credentials: [
    {
      id: "",
      name: "B. Brasil",
      credentials_json: {
        MERCHANT_KEY: "",
        MERCHANT_ID: "",
      },
    },
    {
      id: "",
      name: "Cielo",
      credentials_json: {
        client_id: "",
        client_secret: "",
        app_key: "",
        auth_code: "",
      },
    },
  ],
};
export default {
  watch: {
    companyDialog(v) {
      if (v) {
        setTimeout(() => {
          let tabs = document.querySelectorAll("#companyFormTab .v-tab");
          tabs[0].click();
        }, 10);
        if (this.editData) {
          this.editDataForm = {};
          //console.log("edit company", this.editData);
          if (this.editData.credential_app) {
            this.idpCredencial = [this.editData.credential_app]
          }
          this.editDataForm.services = this.editData.services
            ? [...this.editData.services.map((serv) => serv.service_id)]
            : [];

          this.editDataForm.servicesToTemplate = this.editData.services
            ? [...this.editData.services.map((serv) => serv.service[0])]
            : [];

          this.editDataForm.company = this.editData.company;
          this.editDataForm.erp = this.editData.erp_credentials;
          if (this.editData.contract_manager_credentials) {
            //console.log("templateList");
            this.editDataForm.contract_management_credentials =
              this.editData.contract_manager_credentials.map((obj) => {
                obj.name = obj.contracts_management.name;
                obj.apiKey = obj.contracts_management.endpoint;
                let templateList = "";
                for (var i = 0; i < obj.contract_template.length; i++) {
                  templateList += `${obj.contract_template[i].name}<br/>`;
                }
                obj.templates = templateList;
                //console.log(templateList);
                return obj;
              });
          }
          this.editDataForm.contract_magnager =
            this.editData.contract_manager_credentials;
          this.editDataCredentials = this.editData.payment_credentials;
        }
      } else {
        this.idpCredencial = null;
        this.editDataForm = null;
        this.editDataCredentials = null;
        this.formData = { ...resetCompanyForm };
      }
    },
  },
  methods: {
    updateDigitalSignatures(data) {
      this.editDataForm.contract_management_credentials = [...data];
    },
    saveCompany() {
      //console.log("savecompany");
      let comapnyValidation = this.$refs.companyFormDetails.validForm();
      let credencialValidation;
      if (this.$refs.credentialFormDetails) {
        credencialValidation = this.$refs.credentialFormDetails.validForm();
      } else {
        credencialValidation = true;
      }
      let tabs = document.querySelectorAll("#companyFormTab .v-tab");
      if (!comapnyValidation) {
        tabs[0].click();
        return;
      }
      if (!credencialValidation) {
        tabs[1].click();
        return;
      }
      this.formData.company = this.$refs.companyFormDetails.formData.company;

      this.formData.erp_credentials = this.$refs.companyFormDetails.formData.erp
        .erp_gateway_id
        ? this.$refs.companyFormDetails.formData.erp
        : null;

      this.formData.contract_manager_credentials = this.$refs.companyFormDetails
        .formData.contract_magnager.contracts_management_id
        ? this.$refs.companyFormDetails.formData.contract_magnager
        : null;
      // if (this.formData.contract_manager_credentials) {
      //   this.formData.contract_manager_credentials.active =true;
      // }

      let hasCredential = false;
      if (this.$refs.credentialFormDetails) {
        if (
          this.$refs.credentialFormDetails.cieloMode ||
          this.$refs.credentialFormDetails.bBMode
        ) {
          hasCredential = true;
        }
      }
      // console.log(
      //   "cred",
      //   this.$refs.credentialFormDetails.editDataCredentials[0].id
      // );
      this.formData.services =
        this.$refs.companyFormDetails.formData.services || [];

      this.formData.payment_credentials = !hasCredential
        ? null
        : [
          !this.$refs.credentialFormDetails.cieloMode
            ? undefined
            : {
              id:
                !this.$refs.credentialFormDetails.editDataCredentials ||
                  !this.$refs.credentialFormDetails.editDataCredentials[0]
                  ? null
                  : this.$refs.credentialFormDetails.editDataCredentials[0]
                    .id,
              name: "Cielo",
              credentials_json: {
                ...this.$refs.credentialFormDetails.formData[0],
              },
            },
          !this.$refs.credentialFormDetails.bBMode
            ? undefined
            : {
              id:
                !this.$refs.credentialFormDetails.editDataCredentials ||
                  !this.$refs.credentialFormDetails.editDataCredentials[1]
                  ? null
                  : this.$refs.credentialFormDetails.editDataCredentials[1]
                    .id,
              name: "B.Brasil",
              credentials_json: {
                ...this.$refs.credentialFormDetails.formData[1],
              },
            },
        ];
      this.formData.payment_credentials = !this.formData.payment_credentials
        ? null
        : this.formData.payment_credentials.filter(function (el) {
          return el != null;
        });

      console.log("savecomp", this.formData);
      //return;

      let url = this.formData.company.id
        ? "/api/company/update"
        : "/api/company/create";
      var obj = {
        url: url,
        query: this.formData,
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) return;
        this.toogleCompanyDilog();
        this.getCompanies();
        let opts = {
          message: `Empresa ${this.formData.company.id ? "atualizada" : "criada"
            } com sucesso`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
  },
  data() {
    return {
      idpCredencial: null,
      editDataForm: null,
      editDataCredentials: null,
      tab: null,
      formData: { ...resetCompanyForm },
    };
  },
  props: {
    editData: Object,
    companyDialog: Boolean,
    toogleCompanyDilog: Function,
    getCompanies: Function,
  },
  components: {
    CompanyForm,
    Credentials,
    ManagerContractsForm,
    IdpCredencial,
  },
};
</script>

<style></style>