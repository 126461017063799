var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-16 pr-2"},[_c('v-card',{staticClass:"mx-auto mt-3 mb-9 bgTransparente",attrs:{"min-height":_vm.windowH,"max-width":"calc(100% - 10px)","elevation":"0"}},[_c('v-card-title',{staticClass:"ml-15 pt-8 pl-0 black--text"},[_vm._v("Empresas cadastradas")]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-13 mr-10",attrs:{"color":"primary","fab":"","dark":"","fixed":_vm.responsiveSize < 500,"absolute":_vm.responsiveSize >= 500,"bottom":_vm.responsiveSize < 500,"top":_vm.responsiveSize >= 500,"right":""},on:{"click":_vm.toogleCompanyDilog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Adicionar nova empresa")])]),_c('v-data-table',{staticClass:"elevation-0 opacityTable mx-15",attrs:{"light":"","headers":_vm.headers,"items":_vm.companies,"items-per-page":10},scopedSlots:_vm._u([{key:"item.cnpj",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCnpjCpf")(item.cnpj))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at.split("T")[0]))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1 mr-3",attrs:{"x-small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.editCompany(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" edit ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Editar empresa")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1",attrs:{"x-small":"","color":"red","dark":""},on:{"click":function($event){return _vm.confirmDelCompany(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" mdi-delete-forever ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Excluir empresa")])])]}}])})],1),_c('CompanyDialog',{attrs:{"editData":_vm.editData,"getCompanies":_vm.getCompanies,"companyDialog":_vm.companyDialog,"toogleCompanyDilog":_vm.toogleCompanyDilog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }