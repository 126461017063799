<template>
  <v-form class="mt-15" ref="companyForm" v-model="valid">
    <v-row>
      <v-col cols="12" sm="4" md="4" class="px-15 pt-0">
        <v-subheader class="pl-0 mb-5">
          <h3>Configurações da empresa</h3>
        </v-subheader>
        <v-text-field v-model="formData.company.name" label="Nome da empresa"
          :rules="$store.state.formRules.required"></v-text-field>
        <div class="mt-3">
          <v-text-field-simplemask v-model="formData.company.cnpj" v-bind:label="`CNPJ`" v-bind:properties="{
            rules: [
              $store.state.formRules.cpfCnpjRules[0](
                formData.company.cnpj ? formData.company.cnpj : ''
              ),
            ],
            prefix: '',
            suffix: '',
            readonly: false,
            disabled: false,
            outlined: false,
            clearable: false,
            placeholder: '',
          }" v-bind:options="{
  inputMask: '##.###.###/####-##',
  outputMask: '##############',
  empty: null,
  applyAfter: false,
  alphanumeric: true,
  lowerCase: false,
}" />
        </div>
        <v-textarea class="mt-5" filled name="input-7-4" label="Informação adicional"
          v-model="formData.company.additional_information"></v-textarea>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="12" sm="4" md="4" class="px-15 pt-0">
        <v-subheader class="pl-0 mb-5">
          <h3>Dados de ERP</h3>
        </v-subheader>
        <!-- :rules="$store.state.formRules.required" -->
        <v-select clearable v-model="selectedERP" :items="ERPs" item-text="name" item-value="id" label="Selecionar ERP"
          return-object></v-select>
        <v-text-field :disabled="selectedERP == null ? true : false" v-model="formData.erp.email" label="E-mail"
          :rules="selectedERP == null ? [] : $store.state.formRules.emailRules"></v-text-field>
        <v-text-field :disabled="selectedERP == null ? true : false"
          :rules="selectedERP == null ? [] : $store.state.formRules.required" autocomplete="new-password"
          v-model="formData.erp.password" label="Senha" :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPass = !showPass" :type="showPass ? 'text' : 'password'"></v-text-field>
      </v-col>
      <v-divider vertical></v-divider>
      <!-- :rules="$store.state.formRules.required" -->
      <v-col cols="12" sm="4" md="4" class="px-10 pt-0">
        <v-subheader class="pl-0 mb-0">
          <h3>Serviços</h3>
        </v-subheader>
        <v-select @change="onChangeServices" v-model="formData.services" :rules="$store.state.formRules.required"
          :items="servicesList" item-text="name" item-value="id" chips label="Selecione os serviços" multiple></v-select>
        <v-switch :disabled="dtvgoDisabled" v-model="formData.company.is_partner"
          label="Permitir subscrições DTVgo"></v-switch>
        <span class="caption" style="max-width: 370px; display: block">Habilitando essa
          opção a empresa permitirá o
          envio de subscrições de outros provedores.</span>
        <v-text-field v-if="!dtvgoDisabled" class="mt-2" v-model="formData.company.apikey"
          label="Api Key DTVgo"></v-text-field>
        <span v-if="!dtvgoDisabled" class="caption" style="max-width: 370px; display: block">Preencha com as
          credenciais DTVgo para permitir
          subiscrições.</span>
        <!-- <v-select
          clearable
          v-model="selectedMngsContract"
          :items="mngsContracts"
          item-text="name"
          item-value="id"
          label="Selecionar um gerenciador de contarto"
          return-object
        ></v-select>
        <v-text-field
          :disabled="selectedMngsContract == null ? true : false"
          v-model="formData.contract_magnager.credentials"
          label="Api Key"
          :rules="
            selectedMngsContract == null ? [] : $store.state.formRules.required
          "
        ></v-text-field> -->
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
const resetCompanyForm = {
  company: { name: "", cnpj: "", additional_information: "", is_partner: false, apikey: "", service_id: "" },
  services: [],
  erp: { erp_gateway_id: "", email: "", password: "" },
  contract_magnager: { contracts_management_id: "", credentials: "" },
};
export default {
  watch: {
    dtvgoDisabled(v, oldV) {
      if (!oldV) {
        console.log('hghghg')
        this.formData.company.is_partner = false
      }
    },
    companyDialog(v) {
      //console.log("aqui");
      if (!v) {
        this.$refs.companyForm.reset();
        this.formData = { ...resetCompanyForm };
      } else {
        if (this.editDataForm) {

          this.formData.services = this.editDataForm.services.length
            ? [...this.editDataForm.services]
            : [];
          this.formData.company = { ...this.editDataForm.company };
          if (this.editDataForm.erp) {
            let ind = this.ERPs.findIndex(
              (item) => item.id == this.editDataForm.erp.erp_gateway_id
            );
            this.selectedERP = this.ERPs[ind];

            this.formData.erp = { ...this.editDataForm.erp };
          }
          if (this.editDataForm.contract_magnager) {
            let ind = this.mngsContracts.findIndex(
              (item) =>
                item.id ==
                this.editDataForm.contract_magnager.contracts_management_id
            );
            this.selectedMngsContract = this.mngsContracts[ind];

            this.formData.contract_magnager = {
              ...this.editDataForm.contract_magnager,
            };
          }
          setTimeout(() => {
            this.onChangeServices()
          }, 100);
        }
      }
    },
    selectedERP(v) {
      //console.log("erp");
      if (!v) {
        this.formData.erp.email = "";
        this.formData.erp.erp_gateway_id = "";
        this.formData.erp.password = "";
      } else {
        this.formData.erp.erp_gateway_id = v.id;
      }
      //console.log(this.formData.erp.erp_gateway_id);
      this.$refs.companyForm.resetValidation();
    },
    selectedMngsContract(v) {
      if (!v) {
        this.formData.contract_magnager.credentials = "";
        this.formData.contract_magnager.contracts_management_id = "";
      } else {
        this.formData.contract_magnager.contracts_management_id = v.id;
      }
      //console.log(this.formData.contract_magnager.contracts_management_id);
      this.$refs.companyForm.resetValidation();
    },
  },
  mounted() {
    this.getServices();
    this.getGateways().then((_) => {
      this.getMngContracts().then((_) => {
        if (this.editDataForm) {
          //console.log("aqui");
          this.formData.services = this.editDataForm.services.length
            ? [...this.editDataForm.services]
            : [];
          this.formData.company = { ...this.editDataForm.company };
          if (this.editDataForm.erp) {
            let ind = this.ERPs.findIndex(
              (item) => item.id == this.editDataForm.erp.erp_gateway_id
            );
            this.selectedERP = this.ERPs[ind];

            this.formData.erp = { ...this.editDataForm.erp };
          }
          if (this.editDataForm.contract_magnager) {
            let ind = this.mngsContracts.findIndex(
              (item) =>
                item.id ==
                this.editDataForm.contract_magnager.contracts_management_id
            );
            this.selectedMngsContract = this.mngsContracts[ind];

            this.formData.contract_magnager = {
              ...this.editDataForm.contract_magnager,
            };
          }
          setTimeout(() => {
            this.onChangeServices()
          }, 100);
        }
      });
    });
  },
  methods: {
    onChangeServices() {
      let ids = this.formData.services
      this.dtvgoDisabled = true
      this.formData.company.service_id = ""
      ids.map((id) => {
        for (let i = 0; i < this.servicesList.length; i++) {
          if (this.servicesList[i].id == id && this.servicesList[i].prefix == 'dtvgo') {
            this.formData.company.service_id = id
            this.dtvgoDisabled = false
          }
        }
      })
    },
    getServices() {
      var obj = {
        url: "/api/service/get-all",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.servicesList = [...response.data];
      });
    },
    validForm() {
      if (!this.$refs.companyForm.validate()) {
        return false;
      } else {
        return true;
      }
    },
    async getGateways() {
      var obj = {
        url: "/api/erp-gateway/get-all",
        query: null,
        method: "get",
      };
      await this.$store.dispatch("request/doRequest", obj).then((response) => {
        this.ERPs = [...response.data];
      });
    },
    async getMngContracts() {
      var obj = {
        url: "/api/contract-manager/get-all",
        query: null,
        method: "get",
      };
      await this.$store.dispatch("request/doRequest", obj).then((response) => {
        this.mngsContracts = [...response.data];
      });
    },
  },
  getCboSelected(list, id) {
    return list.findIndex((item) => item.id === id);
  },
  data() {
    return {
      dtvgoDisabled: true,
      servicesList: [],
      formData: { ...resetCompanyForm },
      ERPs: [],
      mngsContracts: [],
      selectedERP: null,
      selectedMngsContract: null,
      showPass: false,
      valid: true,
    };
  },
  props: {
    companyDialog: Boolean,
    editDataForm: Object,
  },
};
</script>

<style></style>