<template>
  <div>
    <v-row class="pt-6 px-4 pb-7">
      <v-col class="pb-0" cols="12" sm="3">
        <v-card flat class="pt-3 font-weight-bold">
          Minhas credenciais IDP
          <v-tooltip right max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="primary"
                size="18"
                class="mb-1 ml-1 cPointer"
                >help_outline</v-icon
              ></template
            ><span class=""
              >Credenciais utilizadas para identificar e autenticar o provedor,
              quando o assinante faz login através de sua operadora.</span
            ></v-tooltip
          >
        </v-card>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="idpCredencial"
      :items-per-page="5"
      class="elevation-0"
    ></v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    idpCredencial: Array,
  },
  data() {
    return {
      headers: [
        { text: "Client ID", value: "client_id", sortable: false },
        { text: "IDP", value: "idp", sortable: false },
        { text: "Client secret", value: "client_secret", sortable: false },
        {
          text: "Url de redirecionamento",
          value: "url_redirect",
          sortable: false,
        },
      ],
    };
  },
};
</script>

<style>
</style>