<template>
  <div class="pl-16 pr-2">
    <v-card
      :min-height="windowH"
      class="mx-auto mt-3 mb-9 bgTransparente"
      max-width="calc(100% - 10px)"
      elevation="0"
    >
      <v-card-title class="ml-15 pt-8 pl-0 black--text"
        >Empresas cadastradas</v-card-title
      >
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="toogleCompanyDilog"
            v-bind="attrs"
            v-on="on"
            class="mt-13 mr-10"
            color="primary"
            fab
            dark
            :fixed="responsiveSize < 500"
            :absolute="responsiveSize >= 500"
            :bottom="responsiveSize < 500"
            :top="responsiveSize >= 500"
            right
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Adicionar nova empresa</span>
      </v-tooltip>
      <v-data-table
        light
        :headers="headers"
        :items="companies"
        :items-per-page="10"
        class="elevation-0 opacityTable mx-15"
      >
        <template v-slot:item.cnpj="{ item }">
          {{ item.cnpj | formatCnpjCpf }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at.split("T")[0] | formatDate }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="btMicro px-1 mr-3"
                @click="editCompany(item.id)"
                x-small
                color="primary"
                dark
                ><v-icon center x-small> edit </v-icon></v-btn
              >
            </template>
            <span class="miniTtip">Editar empresa</span>
          </v-tooltip>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="btMicro px-1"
                @click="confirmDelCompany(item)"
                x-small
                color="red"
                dark
                ><v-icon center x-small> mdi-delete-forever </v-icon></v-btn
              >
            </template>
            <span class="miniTtip">Excluir empresa</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <CompanyDialog
      :editData="editData"
      :getCompanies="getCompanies"
      :companyDialog="companyDialog"
      :toogleCompanyDilog="toogleCompanyDilog"
    />
  </div>
</template>

<script>
import CompanyDialog from "./CompanyDialog.vue";
import MyFilters from "../../filters/genericFilters";
export default {
  computed: {
    responsiveSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  methods: {
    getCompanies() {
      var obj = {
        url: "/api/company/get-all",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        this.companies = [...response.data];
      });
    },
    editCompany(id) {
      var obj = {
        url: "/api/company/getById",
        query: { id: id },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        //console.log(response);
        this.editData = response.data;
        //console.log(this.byID);
        //this.editData = this.byID;
        this.companyDialog = true;
      });
      //   this.editGatewayData = { ...data };
      //   this.gatewayDialog = true;
    },
    confirmDelCompany(data) {
      let obj = {
        message: `Você deseja excluir a empresa <b>${data.name}</b>?`,
        title: "Excluir empresa",
        callback: (_) => {
          this.delCompany(data);
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    delCompany(data) {
      let obj = {
        url: "/api/company/delete",
        query: { id: data.id },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) return;
        let index = this.companies.findIndex(
          (company) => company.id === data.id
        );
        this.companies.splice(index, 1);
        let opts = {
          message: `Empresa <b>${data.name}</b> removida com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
    toogleCompanyDilog() {
      this.companyDialog = !this.companyDialog;
      this.editData = null;
    },
    getHeightPage() {
      this.windowH = window.innerHeight - 125;
    },
  },
  data() {
    return {
      windowH: "580",
      editData: null,
      editGatewayData: null,
      companyDialog: false,
      headers: [
        { text: "Nome", value: "name", sortable: true },
        { text: "CNPJ", value: "cnpj", sortable: false },
        {
          text: "Cadastrada em",
          value: "created_at",
          sortable: false,
        },
        { text: "Ações", value: "actions", sortable: false },
      ],
      companies: [],
      byID: {
        company: {
          id: 131,
          name: "Company 2",
          cnpj: "39967427000107",
          additional_information: null,
          created_at: "2021-08-30T15:36:21.000000Z",
          updated_at: "2021-08-30T15:36:21.000000Z",
        },
        contract_management_credentials: [
          {
            id: 51,
            contracts_management_id: 1,
            company_id: 131,
            active: 1,
            created_at: "2021-08-30T21:30:56.000000Z",
            updated_at: "2021-08-30T21:30:56.000000Z",
            credentials:
              "ee59ed27d00c68f7a7af3103197f72e547c1625bd232e3a99f2af7b808afee92",
            contracts_management: {
              id: 1,
              name: "HelloSign",
              cnpj: "66855437000115",
              endpoint: "123321123",
              created_at: "2021-08-20T17:10:27.000000Z",
              updated_at: "2021-08-20T17:10:27.000000Z",
            },
            contract_template: [],
          },
          {
            id: 73,
            contracts_management_id: 1,
            company_id: 131,
            active: 1,
            created_at: "2021-12-02T14:48:10.000000Z",
            updated_at: "2021-12-02T14:48:10.000000Z",
            credentials: "234",
            contracts_management: {
              id: 1,
              name: "HelloSign",
              cnpj: "66855437000115",
              endpoint: "123321123",
              created_at: "2021-08-20T17:10:27.000000Z",
              updated_at: "2021-08-20T17:10:27.000000Z",
            },
            contract_template: [
              {
                id: 21,
                contract_management_credential_id: 73,
                template_id: "432234",
                name: "234",
                draft_contract: null,
                created_at: "2021-12-02T14:48:10.000000Z",
                updated_at: "2021-12-02T14:48:10.000000Z",
              },
            ],
          },
          {
            id: 75,
            contracts_management_id: 13,
            company_id: 131,
            active: 1,
            created_at: "2021-12-02T14:49:51.000000Z",
            updated_at: "2021-12-02T14:49:51.000000Z",
            credentials: "teste321",
            contracts_management: {
              id: 13,
              name: "Teste 1233",
              cnpj: "20706218000173",
              endpoint: "123123123",
              created_at: "2021-11-29T21:02:37.000000Z",
              updated_at: "2021-11-29T21:02:37.000000Z",
            },
            contract_template: [
              {
                id: 23,
                contract_management_credential_id: 75,
                template_id: "312123",
                name: "123",
                draft_contract: null,
                created_at: "2021-12-02T14:49:51.000000Z",
                updated_at: "2021-12-02T14:49:51.000000Z",
              },
            ],
          },
        ],
        payment_credentials: [
          {
            id: 99,
            company_id: 131,
            payment_gateway_id: null,
            credentials_json: '{"MERCHANT_KEY":"123","MERCHANT_ID":"123"}',
            created_at: "2021-08-30T15:36:21.000000Z",
            updated_at: "2021-08-30T15:37:00.000000Z",
            name: "Cielo",
          },
          {
            id: 101,
            company_id: 131,
            payment_gateway_id: null,
            credentials_json:
              '{"client_id":"123","client_secret":"123","app_key":"123","auth_code":"123"}',
            created_at: "2021-08-30T15:37:00.000000Z",
            updated_at: "2021-08-30T15:37:00.000000Z",
            name: "B.Brasil",
          },
        ],
        erp_credentials: [
          {
            id: 107,
            company_id: 131,
            erp_gateway_id: 1,
            email: "www@rrr.com",
            password: "123123123",
            created_at: "2021-08-30T21:30:40.000000Z",
            updated_at: "2021-08-30T21:30:40.000000Z",
          },
        ],
      },
    };
  },
  beforeMount() {
    this.getHeightPage();
  },
  mounted() {
    this.getCompanies();
  },
  created() {
    window.addEventListener("resize", this.getHeightPage);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeightPage);
  },
  components: {
    CompanyDialog,
  },
};
</script>

<style>
</style>